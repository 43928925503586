<template>
  <component :is="layout ? layout : 'NoneLayout'">
    <router-view :key="$store.state.forceRerender" />
  </component>
</template>

<script>
import DefaultLayout from "./DefaultLayout";
import NoneLayout from "./NoneLayout";

export default {
  name: "BaseLayout",
  components: {
    DefaultLayout,
    NoneLayout,
  },
  data() {
    return {};
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "NoneLayout";
    },
  },
};
</script>
