import {confirmed, email, integer, length, max, min, min_value, numeric, required, regex} from 'vee-validate/dist/rules'
import {extend, localize} from 'vee-validate'
import ko from 'vee-validate/dist/locale/ko.json'

localize('ko', ko)

extend('required', {
  ...required,
  // message: 'This field is required1'
})

extend('email', {
  ...email,
  // message: 'This field must be a valid email'
})

extend('confirmed', {
  ...confirmed,
  // message: 'This field confirmation does not match'
})

extend('length', {
  ...length,
})

extend('numeric', {
  ...numeric,
})

extend('integer', {
  ...integer,
})

extend('min', {
  ...min,
})

extend('min_value', {
  ...min_value,
})

extend('max', {
  ...max,
})

extend('regex', {
  ...regex,
})

extend('id', (value) => {
  if (!/(^[a-zA-Z])/.test(value)) {
    return '아이디의 처음은 영문이여야 합니다.'
  }
  if (!/^[a-z0-9_-]{4,20}$/.test(value)) {
    return '아이디는 (영문소문자, 숫자, -,_)의 4~20자리로 입력해주세요.'
  }
  // if (!/^[a-zA-Z]{1}[a-zA-Z0-9_]{4,20}$/.test(value)) {
  //   return '첫글자는 영문이여야 하며, (영문소문자, 숫자, -,_)의 5~20자리로 입력해주세요.'
  // }

  return true
})

extend('check-pwd', (value) => {
  if (!/^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/.test(value)) {
    return "비밀번호를 올바르게 입력해주세요."
  }
  return true;
})

extend('license', (value) => {
  if (!/^([0-9]{3})-([0-9]{2})-([0-9]{5})$/.test(value)) {
    return "'-' 을 포함한 사업자 번호 10자리를 올바르게 입력해주세요."
  }
  return true;
})

/*extend('unique', (value) => {
  if (!/^(?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )$/.test(value)) {
    return "숫자와 소수점 하나만 허용합니다."
  }
  return true;
})*/
