/**
 * Vue 처리용
 * @author souzo with github
 *  main.js
 *  import dayJs from './plugins/dayjs'
 *  Vue.use(dayJs) 또는 Vue.use(dayJs, 'ago')
 */

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
// import en from "dayjs/locale/en";
// import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(timezone);

// dayjs.locale(en);
// dayjs.extend(relativeTime);

const InvalidReturn = "";
const LangSet = {
  kr: {
    Y: "년",
    M: "월",
    D: "일",
    h: "시간",
    m: "분",
    s: "초",
    ago: "이전",
    just: "방금",
  },
  en: {
    Y: "year",
    M: "month",
    D: "day",
    h: "hour",
    m: "minute",
    s: "second",
    ago: "ago",
    just: "just now",
  },
};
// 두자리 만들기
const wrapZero = function (n) {
  return n < 10 ? "0" + n : n;
};

// 타이머 기록용
let timer = {};

export default {
  /**
   * @param {Vue} Vue
   * @param {object} options
   */
  install(Vue, options = {}) {
    // 표시 언어
    const lang = LangSet[options.lang || "kr"];

    // 디버깅 활성화 여부
    const debug = options.debug || false;

    // 활성화할 필터
    const filters = options.filters || {
      ago: "ago",
    };

    // 신규 명령어
    const directives = options.directives || {
      countdown: "countdown",
    };

    // dayjs 선언하기
    Vue.prototype.$dayjs = dayjs;

    /**
     * 카운트 다운 명령어
     */
    "countdown" in directives &&
      Vue.directive(directives["countdown"], (el, binding) => {
        let from, target, dArray, offset, str, format;

        if (!binding.value || !binding.value.target) {
          return (el.innerText = InvalidReturn);
        }
        format = binding.value.format || "HH:mm:ss";
        target = dayjs(binding.value.target);
        if (!target.isValid()) {
          return (el.innerText = InvalidReturn);
        }
        timer[target.valueOf()] && clearTimeout(timer[target.valueOf()]);
        const count = () => {
          from = dayjs();
          dArray =
            from.valueOf() <= target.valueOf()
              ? [target, from]
              : [target, target];
          let diffD = dArray[0].diff(dArray[1], "day");
          let diffh, diffm, diffs;
          offset = dArray[0];
          str = format;
          if (diffD > 0 && format.match("DD")) {
            str = str.replace("DD", diffD);
            offset = offset.subtract(diffD, "day");
          }
          diffh = offset.diff(dArray[1], "hour");
          str = str.replace("HH", wrapZero(diffh));
          offset = offset.subtract(diffh, "hour");
          diffm = offset.diff(dArray[1], "minute");
          str = str.replace("mm", wrapZero(diffm));
          offset = offset.subtract(diffm, "minute");
          diffs = offset.diff(dArray[1], "second");
          str = str.replace("ss", wrapZero(diffs));
          el.innerText = str;
          timer[target.valueOf()] = setTimeout(count, 1000);
        };
        count();
      });

    /**
     * dayjs 필터 동작
     */
    Vue.filter("dayjs", (value, method, ...params) => {
      let d = dayjs(value);
      if (!d.isValid()) return InvalidReturn;
      if (method) {
        return d[method].apply(d, params);
      }
    });

    /**
     * 1일전 2일전 등 표기 형싱
     */
    "ago" in filters &&
      Vue.filter(filters["ago"], (value) => {
        const p = dayjs(value);
        const d = dayjs();
        let str = "";

        if (!p.isValid()) return InvalidReturn;
        let diffY = d.diff(p, "year");
        let diffM = d.diff(p, "month");
        let diffD = d.diff(p, "day");
        let diffh = d.diff(p, "hour");
        let diffm = d.diff(p, "minute");
        let diffs = 0;
        let offset = null;

        if (diffY > 0) {
          return p.format("YYYY-MM-DD");
        }

        if (diffM > 0) {
          str = diffM + lang.M;
          return str + lang.ago;
        }

        if (diffD > 0) {
          str += diffD + lang.D;
          return str + lang.ago;
        }

        if (diffh > 0) {
          str += diffh + lang.h;
          return str + lang.ago;
        }

        if (diffm > 0) {
          str += diffm + lang.m;
          return str + lang.ago;
        }

        if (diffs > 0) {
          if (diffs < 30) {
            return lang.just;
          }
          str += diffs + lang.s;
        }

        return str + lang.ago;
      });
  },
};
