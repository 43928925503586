import i18n from "@/i18n";

const routes = {
  path: "/event",
  name: "event",
  component: () =>
    import(/* webpackChunkName: "event" */ "@/views/event/EventMain"),
  children: [
    {
      path: "netizenlesson",

      name: "NetizenLesson",
      component: () =>
        import(/* webpackChunkName: "event" */ "@/views/event/NetizenLesson"),

      children: [
        {
          path: "swinglist",
          name: "SwingList",
          alias: "",
          component: () =>
            import(/* webpackChunkName: "event" */ "@/views/event/SwingList"),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("menu.event.netizen_lesson"),
            m_subtitle: i18n.t("menu.event.netizen_lesson"),
            menu_num: 3,
            sub_menu_num: 31,
            step: [
              i18n.t("menu.event.event"),
              i18n.t("page.event.netizen_lesson"),
            ],
          },
        },
        {
          path: "netizenlessonview/:id",
          name: "NetizenLessonView",
          component: () =>
            import(
              /* webpackChunkName: "event" */ "@/views/event/NetizenLessonView"
            ),
          prop: true,
          meta: {
            layout: "DefaultLayout",
            wrapId: "sub",
            subtitle: i18n.t("menu.event.netizen_lesson"),
            m_subtitle: i18n.t("menu.event.netizen_lesson"),
            menu_num: 3,
            sub_menu_num: 31,
            step: [
              i18n.t("menu.event.event"),
              i18n.t("page.event.netizen_lesson"),
            ],
          },
        },
      ],
    },
    {
      path: "minigame",
      alias: "",
      name: "MiniGame",
      component: () =>
        import(/* webpackChunkName: "event" */ "@/views/event/MiniGame"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.event.minigame"),
        m_subtitle: i18n.t("menu.event.minigame"),
        menu_num: 3,
        sub_menu_num: 32,
        step: [i18n.t("menu.event.event"), i18n.t("page.event.minigame")],
      },
    },

    {
      path: "winaminigame",

      name: "WinAMiniGame",
      component: () =>
        import(/* webpackChunkName: "event" */ "@/views/event/WinAMiniGame"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.event.minigame_winners"),
        m_subtitle: i18n.t("menu.event.minigame_winners"),
        menu_num: 3,
        sub_menu_num: 33,
        step: [
          i18n.t("menu.event.event"),
          i18n.t("page.event.minigame_winners"),
        ],
      },
    },
    {
      path: "joinminigame",
      name: "JoinMiniGame",
      component: () =>
        import(/* webpackChunkName: "event" */ "@/views/event/JoinMinigame"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.event.minigame_informaition"),
        m_subtitle: i18n.t("menu.event.minigame_informaition"),
        menu_num: 3,
        sub_menu_num: 34,
        step: [
          i18n.t("menu.event.event"),
          i18n.t("page.event.minigame_informaition"),
        ],
      },
    },
    {
      path: "minigameranking/:eventno+",
      name: "MiniGameRanking",
      component: () =>
        import(/* webpackChunkName: "event" */ "@/views/event/MiniGameRanking"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.event.minigame"),
        m_subtitle: i18n.t("page.event.minigame"),
        menu_num: 3,
        sub_menu_num: 32,
        step: [i18n.t("menu.event.event"), i18n.t("page.event.minigame")],
      },
    },
    {
      path: "winaminigamelist/:eventno+",
      name: "WinAMiniGameList",
      component: () =>
        import(
          /* webpackChunkName: "event" */ "@/views/event/WinAMiniGameList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.event.minigame"),
        m_subtitle: i18n.t("page.event.minigame"),
        menu_num: 3,
        sub_menu_num: 32,
        step: [i18n.t("menu.event.event"), i18n.t("page.event.minigame")],
      },
    },
  ],
};

export default routes;
