import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./translationmodules/locales/en/translation.json";
import ko from "./translationmodules/locales/ko/translation.json";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "en",
  fallbackLocale: "ko",
  messages: { en, ko },
});
