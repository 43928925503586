import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";

// 토너먼트 리스트 가지고 오기
const get_tournamentlist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    tournamentlistcnt: 0,
    tournamentlist: [],
  };
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/tournament/tournamentlist",
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
    params: {
      searchtype: param.searchtype,
      searchname: param.searchname,
      tourstatus_fk: param.tourstatus_fk,
      tourtype_fk: param.tourtype_fk,
      tour_rank_type: param.tour_rank_type,
      pageno: param.pageno,
      pagesize: param.pagesize,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_tournamentlist", err);
    result(false, err);
  }
};

// 토너먼트 조건 불러오기
const get_tournamentcondition = async (tournamentno, result) => {
  let finalData = {
    code: "",
    message: "",
    tournamentcondition: [],
  };
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/tournament/tournamentcondition/" +
      tournamentno,
    //url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
  };
  try {
    await axios(options).then((response) => {
      console.log(response);
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_tournamentcondition", err);
  }
};

// 토너먼트 랭킹 가져오기
const get_tournamentranklist = async (param, result) => {
  let finalData = {
    code: "",
    message: "",
    pageno: 1,
    pagesize: 10,
    tournamentname: "",
    tournamentranklistcnt: 0,
    tournamentranklist: [],
  };
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url:
      process.env.VUE_APP_API_URL +
      "/tournament/tournamentranking/" +
      param.tournamentno,
    //   url: 'http://localhost/test.php',
    headers: { Authorization: "Bearer " + token },
    params: {
      scoretype: param.scoretype,
      gender: param.gender,
      pageno: param.pageno,
      pagesize: param.pagesize,
      tour_rank_type: param.tour_rank_type,
    },
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("get_tournamentranklist", err);
    result(false, err);
  }
};

// 회원정보보기
const get_playerinfo = async (player, type, result) => {
  let finalData = {
    code: "",
    message: "",
    playerbasicinfo: [],
    playerpalyinfo: [],
  };
  const token = sessionStorage.getItem("token") || null;
  const options = {
    method: "GET",
    url: process.env.VUE_APP_API_URL + "/common/playerinfo",
    headers: { Authorization: "Bearer " + token },
    params: {
      player: player,
      type: type,
    },
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        result(true, finalData);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("get_playerinfo", err);
    result(false, err);
  }
};

export default {
  get_tournamentlist,
  get_tournamentcondition,
  get_tournamentranklist,
  get_playerinfo,
};
