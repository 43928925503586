import i18n from "@/i18n";

const routes = {
  path: "/support",
  name: "support",
  component: () =>
    import(/* webpackChunkName: "support" */ "@/views/support/SupportMain"),
  children: [
    {
      path: "notice",
      alias: "",
      name: "Notice",
      component: () =>
        import(/* webpackChunkName: "support" */ "@/views/support/Notice"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.support.notice"),
        m_subtitle: i18n.t("menu.support.notice"),
        menu_num: 6,
        sub_menu_num: 61,
        step: [i18n.t("menu.support.support"), i18n.t("page.support.notice")],
      },
    },
    {
      path: "noticeview/:id",
      name: "NoticeView",
      component: () =>
        import(/* webpackChunkName: "support" */ "@/views/support/NoticeView"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.support.notice"),
        m_subtitle: i18n.t("menu.support.notice"),
        menu_num: 6,
        sub_menu_num: 61,
        step: [i18n.t("menu.support.support"), i18n.t("page.support.notice")],
      },
    },
    {
      path: "inquiry",
      name: "Inquiry",
      component: () =>
        import(/* webpackChunkName: "support" */ "@/views/support/Inquiry"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.support.inquiry"),
        m_subtitle: i18n.t("menu.support.inquiry"),
        menu_num: 6,
        sub_menu_num: 62,
        step: [i18n.t("menu.support.support"), i18n.t("page.support.inquiry")],
      },
    },
    {
      path: "resources",
      name: "Resources",
      component: () =>
        import(/* webpackChunkName: "support" */ "@/views/support/Resources"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.support.resources"),
        m_subtitle: i18n.t("menu.support.resources"),
        menu_num: 6,
        sub_menu_num: 63,
        step: [
          i18n.t("menu.support.support"),
          i18n.t("page.support.resources"),
        ],
      },
    },
    {
      path: "resourcesview/:id",
      name: "ResourcesView",
      component: () =>
        import(
          /* webpackChunkName: "support" */ "@/views/support/ResourcesView"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.support.resources"),
        m_subtitle: i18n.t("menu.support.resources"),
        menu_num: 6,
        sub_menu_num: 63,
        step: [
          i18n.t("menu.support.support"),
          i18n.t("page.support.resources"),
        ],
      },
    },
  ],
};

export default routes;
