<template>
  <div class="wrap">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'NoneLayout',
}
</script>