import i18n from "@/i18n";

const routes = {
  path: "/halloffame",
  name: "Halloffame",
  component: () =>
    import(
      /* webpackChunkName: "halloffame" */ "@/views/halloffame/HalloffameMain"
    ),

  children: [
    {
      path: "pointleaderboard",
      name: "PointLeaderboard",
      alias: "",
      component: () =>
        import(
          /* webpackChunkName: "halloffame" */ "@/views/halloffame/PointLeaderboard"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.halloffame.point_leaderboard"),
        m_subtitle: i18n.t("menu.halloffame.point_leaderboard"),
        menu_num: 7,
        sub_menu_num: 71,
        step: [
          i18n.t("menu.halloffame.halloffame"),
          i18n.t("menu.halloffame.point_leaderboard"),
        ],
      },
    },

    {
      path: "pointinfo",
      name: "PointInfo",
      component: () =>
        import(
          /* webpackChunkName: "halloffame" */ "@/views/halloffame/PointInfo"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.halloffame.point_info"),
        m_subtitle: i18n.t("menu.halloffame.point_info"),
        menu_num: 7,
        sub_menu_num: 72,
        step: [
          i18n.t("menu.halloffame.halloffame"),
          i18n.t("menu.halloffame.point_info"),
        ],
      },
    },
    {
      path: "stats",
      name: "Stats",
      component: () =>
        import(/* webpackChunkName: "halloffame" */ "@/views/halloffame/Stats"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: i18n.t("menu.halloffame.stats"),
        m_subtitle: i18n.t("menu.halloffame.stats"),
        menu_num: 7,
        sub_menu_num: 73,
        step: [
          i18n.t("menu.halloffame.halloffame"),
          i18n.t("menu.halloffame.stats"),
        ],
      },
    },
  ],
};

export default routes;
